@charset "UTF-8";
#build-page,
#build-page-style,
#build-page-share,
#build-page-results,
#build-page-general {
  padding: 0px;
  margin: auto;
  width: 100%;
  max-width: 1024px;
}

/** ABA CONSTRUTOR  **/
#build-page {
  margin-top: 90px;
}
#build-page .page-block {
  width: 100%;
  border-radius: var(--radius-large);
  border: solid 3px #8d4f7a;
  padding: 0;
  margin-bottom: 20px;
  position: relative;
  background: linear-gradient(180deg, #8d4f7a 20%, #dbcbd7 30%, #dbcbd7 100%);
  /*página inativa*/
}
#build-page .page-block > .edition-page-wrap {
  width: 100%;
  min-height: 35px;
  position: relative;
  border-radius: var(--radius-large);
}
#build-page .page-block > .edition-page-wrap > .wrapped {
  position: absolute;
  border-radius: 35px;
  margin: 5px;
  font-size: 12px;
}
#build-page .page-block > .edition-page-wrap > .wrapped > .id,
#build-page .page-block > .edition-page-wrap > .wrapped > .logic,
#build-page .page-block > .edition-page-wrap > .wrapped > .title,
#build-page .page-block > .edition-page-wrap > .wrapped > .inactive {
  position: relative;
  float: left;
  top: 0;
  border-radius: var(--radius-large);
  height: 24px;
}
#build-page .page-block > .edition-page-wrap > .wrapped > .id {
  background-color: #380029;
  z-index: 4;
  padding: 4px 7px;
  color: white;
}
#build-page .page-block > .edition-page-wrap > .wrapped > .title {
  background-color: #541b46;
  z-index: 3;
  padding: 4px 7px 4px 22px;
  color: white;
  margin-left: -17px;
}
#build-page .page-block > .edition-page-wrap > .wrapped > .logic {
  background-color: #388f3f;
  z-index: 2;
  padding: 4px 7px 4px 20px;
  cursor: pointer;
  color: white;
  margin-left: -17px;
}
#build-page .page-block > .edition-page-wrap > .wrapped > .inactive {
  background-color: #ececec;
  z-index: 1;
  padding: 4px 7px 4px 20px;
  color: #555;
  margin-left: -17px;
}
#build-page .page-block > .edition-page-wrap .buttom-editions {
  position: absolute;
  float: right;
  top: 0;
  right: 5px;
  padding: 5px;
  opacity: 0.2;
  transition: opacity 0.3s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
}
#build-page .page-block > .edition-page-wrap .buttom-editions:hover {
  opacity: 1;
}
#build-page .page-block .empty-page {
  border-top: 1px solid #ddd;
  padding: 10px 0;
  background-color: white;
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}
#build-page .page-block.inactive {
  border: solid 3px #aaa;
  background: linear-gradient(180deg, #aaa 20%, #d6d6d6 30%, #d6d6d6 100%);
}
#build-page .page-block.inactive > .edition-page-wrap > .wrapped > .id {
  background-color: #3f3f3f;
}
#build-page .page-block.inactive > .edition-page-wrap > .wrapped > .title {
  background-color: #505050;
}
#build-page .question-block {
  position: relative;
  padding: 5px;
  background-color: white;
  margin: 6px 6px 10px 6px;
  border-radius: var(--radius-large);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px;
  /*página inativa*/
}
#build-page .question-block > .edition-question-wrap {
  width: 100%;
  min-height: 35px;
  position: relative;
  border-radius: var(--radius-large);
}
#build-page .question-block > .edition-question-wrap > .wrapped {
  position: absolute;
  border-radius: 35px;
  margin: 5px 5px 5px 0;
  font-size: 12px;
}
#build-page .question-block > .edition-question-wrap > .wrapped > .id,
#build-page .question-block > .edition-question-wrap > .wrapped > .logic,
#build-page .question-block > .edition-question-wrap > .wrapped > .require,
#build-page .question-block > .edition-question-wrap > .wrapped > .inactive {
  position: relative;
  float: left;
  top: 0;
  border-radius: var(--radius-large);
  height: 24px;
}
#build-page .question-block > .edition-question-wrap > .wrapped > .id {
  background-color: #635492;
  z-index: 4;
  padding: 4px 7px;
  color: white;
}
#build-page .question-block > .edition-question-wrap > .wrapped > .require {
  background-color: #df005d;
  z-index: 3;
  padding: 4px 7px 4px 20px;
  color: white;
  margin-left: -17px;
}
#build-page .question-block > .edition-question-wrap > .wrapped > .logic {
  background-color: #388f3f;
  z-index: 2;
  padding: 4px 7px 4px 20px;
  cursor: pointer;
  color: white;
  margin-left: -17px;
}
#build-page .question-block > .edition-question-wrap > .wrapped > .inactive {
  background-color: #ccc;
  z-index: 1;
  padding: 4px 7px 4px 20px;
  cursor: pointer;
  color: #555;
  margin-left: -17px;
}
#build-page .question-block > .edition-question-wrap .buttom-editions {
  position: absolute;
  float: right;
  top: 0;
  right: 0px;
  padding: 5px;
  opacity: 0.3;
  transition: opacity 0.3s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
}
#build-page .question-block > .edition-question-wrap .buttom-editions:hover {
  opacity: 1;
}
#build-page .question-block:last-child {
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}
#build-page .question-block > .edit-component-click {
  cursor: pointer;
  border-radius: var(--radius-large);
  background-color: #eff2f9;
  padding: 6px;
  box-shadow: inset rgba(0, 0, 0, 0.25) 0px 0px 5px;
}
#build-page .question-block .has-logic {
  background-color: #d7ecdc;
  width: 110px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
}
#build-page .question-block .is-require {
  background-color: #ff4343;
  width: 26px;
  height: 26px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  color: white;
  text-align: center;
}
#build-page .question-block .is-inactive {
  background-color: #adadad;
  height: 26px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  color: white;
}
#build-page .question-block .addition-block {
  width: 100%;
  height: 0px;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}
#build-page .question-block .addition-block > .caption {
  width: 100%;
}
#build-page .question-block .addition-block > .caption > span {
  margin: auto;
  background-color: #ca5daf;
  width: 70px;
  display: block;
  font-size: 12px;
  border-radius: 6px 6px 0 0;
  color: white;
  text-align: center;
}
#build-page .question-block .addition-block > .addition-content > .addition-content-box {
  border-radius: 20px;
  border: solid 1px #743e66;
  display: flex;
  width: 350px;
  margin: auto;
  overflow: hidden;
}
#build-page .question-block .addition-block > .addition-content > .addition-content-box > .items {
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  background-color: #743e66;
  font-size: 12px;
  cursor: pointer;
  flex: 1;
}
#build-page .question-block .addition-block > .addition-content > .addition-content-box > .items:hover {
  background-color: #49233f;
}
#build-page .question-block:hover .addition-block {
  height: 50px;
}
#build-page .question-block.inactive {
  background-color: #e8eaed;
}
#build-page .question-block.inactive > .edition-question-wrap > .wrapped > .id {
  background-color: #3f3f3f;
}
#build-page .question-block.inactive > .edition-question-wrap > .wrapped > .title {
  background-color: #505050;
}
#build-page .question-block.inactive > .edit-component-click {
  background-color: #bbb;
  opacity: 0.3;
}

#build-page-question-layout div.item {
  margin: 5px 0 10px 0;
  width: 100%;
  clear: both;
}
#build-page-question-layout div.item strong {
  width: 100%;
  clear: both;
}
#build-page-question-layout div.item div.content {
  margin-top: 10px;
  width: 100%;
  clear: both;
}
#build-page-question-layout div.item div.content > .html-preview {
  border: solid 1px #ddd;
  border-radius: 8px;
}
#build-page-question-layout div.item div.content > .html-preview .toolbar {
  padding: 3px;
  border-bottom: solid 1px #eee;
  display: flex;
  justify-content: flex-end;
}
#build-page-question-layout div.item div.content > .html-preview .toolbar button {
  outline: none;
  border: none;
  height: 30px;
  min-width: 30px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 3px;
}
#build-page-question-layout div.item div.content > .html-preview .toolbar button:hover {
  background-color: #ddd;
}
#build-page-question-layout div.item div.content > .html-preview .html {
  padding: 5px 10px;
}
#build-page-question-layout div.item div.content > .question-editor {
  width: 100%;
  display: block;
  height: 40px;
  border-radius: 5px;
  padding: 8px 10px;
  outline: none;
  border: solid 1px #ccc;
  background-color: #f5f5f5;
  cursor: pointer;
}
#build-page-question-layout div.item div.content > .nps,
#build-page-question-layout div.item div.content > .csat {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  gap: 10px;
}
#build-page-question-layout div.item div.content > .nps > .input-item,
#build-page-question-layout div.item div.content > .csat > .input-item {
  border: solid 1px #ccc;
  background-color: #f5f5f5;
  border-radius: 5px;
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
}
#build-page-question-layout div.item div.content > .radiobox > .input-item,
#build-page-question-layout div.item div.content > .checkbox > .input-item,
#build-page-question-layout div.item div.content > .dropdown > .input-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2px;
  margin-bottom: 5px;
  /*antd*/
}
#build-page-question-layout div.item div.content > .radiobox > .input-item .ant-input-group-addon,
#build-page-question-layout div.item div.content > .checkbox > .input-item .ant-input-group-addon,
#build-page-question-layout div.item div.content > .dropdown > .input-item .ant-input-group-addon {
  padding: 0 5px;
  font-size: 12px;
}
#build-page-question-layout div.item div.content > .radiobox > .input-item p,
#build-page-question-layout div.item div.content > .checkbox > .input-item p,
#build-page-question-layout div.item div.content > .dropdown > .input-item p {
  margin: -5px 0 10px 0;
}
#build-page-question-layout div.item div.content > .radiobox > .input-item p > span,
#build-page-question-layout div.item div.content > .checkbox > .input-item p > span,
#build-page-question-layout div.item div.content > .dropdown > .input-item p > span {
  margin-right: 10px;
}
#build-page-question-layout div.item div.content > .radiobox > .input-item p > span.edit-other,
#build-page-question-layout div.item div.content > .checkbox > .input-item p > span.edit-other,
#build-page-question-layout div.item div.content > .dropdown > .input-item p > span.edit-other {
  cursor: pointer;
}
#build-page-question-layout div.item div.content > .radiobox > .input-item p > span.edit-other svg,
#build-page-question-layout div.item div.content > .checkbox > .input-item p > span.edit-other svg,
#build-page-question-layout div.item div.content > .dropdown > .input-item p > span.edit-other svg {
  font-size: 16px;
  position: relative;
  top: 3px;
}
#build-page-question-layout .editor-design .editor-items {
  margin-top: 10px;
  overflow: hidden;
}
#build-page-question-layout .editor-design .editor-items > .input button {
  width: 31%;
  margin-right: 1%;
  height: 50px;
  padding: 0;
}
#build-page-question-layout .editor-design .editor-items > .input button:last-child {
  margin-right: 0;
}
#build-page-question-layout .editor-design .editor-items > .nps .step1,
#build-page-question-layout .editor-design .editor-items > .nps .step2,
#build-page-question-layout .editor-design .editor-items > .nps .step3 {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}
#build-page-question-layout .editor-design .editor-items > .nps .step1 button,
#build-page-question-layout .editor-design .editor-items > .nps .step2 button,
#build-page-question-layout .editor-design .editor-items > .nps .step3 button {
  height: 30px;
  width: 50%;
}
#build-page-question-layout .editor-design .editor-items > .nps .switch {
  margin: 10px 0;
}
#build-page-question-layout .editor-design .editor-items > .nps .nps-preview {
  border: solid 1px #ddd;
  border-radius: 8px;
  overflow: hidden;
}
#build-page-question-layout .editor-design .editor-items > .nps .nps-preview > div.head {
  background-color: #d8d8d8;
  height: 20px;
  padding: 0 5px;
  font-size: 12px;
}
#build-page-question-layout .editor-design .editor-items > .nps .nps-preview > div.item {
  padding: 5px;
  text-align: center;
}
#build-page-question-layout .editor-design .editor-items > .nps .nps-preview > div.item > span {
  display: flex;
  width: 40px;
  height: 40px;
  border: solid 1px #949494;
  border-radius: 5px;
  margin: auto;
  justify-content: center;
  align-items: center;
  color: #222;
  font-weight: 700;
  transition: all 0.1s ease-in-out;
}
#build-page-question-layout .editor-design .editor-items > .nps .nps-preview > div.item > span.rounded {
  border-radius: 50%;
}
#build-page-question-layout .editor-design .editor-items > .nps .nps-preview > div.item > span.shadow {
  box-shadow: 2px 2px 0 0 #414141;
}
#build-page-question-layout .editor-design .editor-items > .nps .nps-preview > div.item > span.triade, #build-page-question-layout .editor-design .editor-items > .nps .nps-preview > div.item > span.colorfull {
  background-color: #38ac00;
  color: #fff;
}
#build-page-question-layout .editor-design .editor-items > .nps .nps-preview > div.item > img.standard-icon {
  height: 40px;
}
#build-page-question-layout .editor-design .editor-items > .nps .nps-preview > div.item > div.model {
  display: flex;
  justify-content: center;
  align-items: center;
}
#build-page-question-layout .editor-design .editor-items > .nps .nps-preview > div.item > div.model > img {
  width: 150px;
  margin-top: 10px;
}
#build-page-question-layout .editor-design .editor-items > .csat .step1,
#build-page-question-layout .editor-design .editor-items > .csat .step2,
#build-page-question-layout .editor-design .editor-items > .csat .step3 {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}
#build-page-question-layout .editor-design .editor-items > .csat .step1 button,
#build-page-question-layout .editor-design .editor-items > .csat .step2 button,
#build-page-question-layout .editor-design .editor-items > .csat .step3 button {
  height: 30px;
  width: 50%;
}
#build-page-question-layout .editor-design .editor-items > .csat .step3 {
  flex-wrap: wrap;
  justify-content: center;
}
#build-page-question-layout .editor-design .editor-items > .csat .step3 button {
  width: 24.55555555%;
}
#build-page-question-layout .editor-design .editor-items > .csat .switch {
  margin: 10px 0;
}
#build-page-question-layout .editor-design .editor-items > .csat .csat-preview {
  border: solid 1px #ddd;
  border-radius: 8px;
  overflow: hidden;
}
#build-page-question-layout .editor-design .editor-items > .csat .csat-preview > div.head {
  background-color: #d8d8d8;
  height: 20px;
  padding: 0 5px;
  font-size: 12px;
}
#build-page-question-layout .editor-design .editor-items > .csat .csat-preview > div.item {
  padding: 5px;
  text-align: center;
}
#build-page-question-layout .editor-design .editor-items > .csat .csat-preview > div.item > span {
  display: flex;
  width: 40px;
  height: 40px;
  border: solid 1px #949494;
  border-radius: 5px;
  margin: auto;
  justify-content: center;
  align-items: center;
  color: #222;
  font-weight: 700;
  transition: all 0.1s ease-in-out;
}
#build-page-question-layout .editor-design .editor-items > .csat .csat-preview > div.item > span.rounded {
  border-radius: 50%;
}
#build-page-question-layout .editor-design .editor-items > .csat .csat-preview > div.item > span.shadow {
  box-shadow: 2px 2px 0 0 #414141;
}
#build-page-question-layout .editor-design .editor-items > .csat .csat-preview > div.item > span.triade, #build-page-question-layout .editor-design .editor-items > .csat .csat-preview > div.item > span.colorfull {
  background-color: #38ac00;
  color: #fff;
}
#build-page-question-layout .editor-design .editor-items > .csat .csat-preview > div.item > img.standard-icon {
  height: 40px;
}
#build-page-question-layout .editor-design .editor-items > .csat .csat-preview > div.item > div.model {
  display: flex;
  justify-content: center;
  align-items: center;
}
#build-page-question-layout .editor-design .editor-items > .csat .csat-preview > div.item > div.model > img {
  width: 100px;
  margin-top: 10px;
}
#build-page-question-layout .editor-design .editor-items > .checkbox > .wrap,
#build-page-question-layout .editor-design .editor-items > .checkbox > .wrap-buttons,
#build-page-question-layout .editor-design .editor-items > .radiobox > .wrap,
#build-page-question-layout .editor-design .editor-items > .radiobox > .wrap-buttons {
  width: 100%;
  clear: both;
  overflow: hidden;
}
#build-page-question-layout .editor-design .editor-items > .checkbox > .wrap,
#build-page-question-layout .editor-design .editor-items > .radiobox > .wrap {
  padding: 10px 0;
}
#build-page-question-layout .editor-design .editor-items > .checkbox > .wrap-buttons button,
#build-page-question-layout .editor-design .editor-items > .radiobox > .wrap-buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-wrap: wrap;
  float: left;
  width: 18.2%;
  margin-right: 2%;
  height: 80px;
  padding: 0;
}
#build-page-question-layout .editor-design .editor-items > .checkbox > .wrap-buttons button img,
#build-page-question-layout .editor-design .editor-items > .radiobox > .wrap-buttons button img {
  width: 30px;
}
#build-page-question-layout .editor-design .editor-items > .checkbox > .wrap-buttons button:last-child,
#build-page-question-layout .editor-design .editor-items > .radiobox > .wrap-buttons button:last-child {
  margin-right: 0;
}
#build-page-question-layout .editor-design .editor-items > .checkbox > .wrap-buttons button.active,
#build-page-question-layout .editor-design .editor-items > .radiobox > .wrap-buttons button.active {
  background-color: #ddc3d5;
}
#build-page-question-layout .editor-design .editor-items .reset-button .step1,
#build-page-question-layout .editor-design .editor-items .reset-button .step2,
#build-page-question-layout .editor-design .editor-items .reset-button .step3 {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}
#build-page-question-layout .editor-design .editor-items .reset-button .step1 > button,
#build-page-question-layout .editor-design .editor-items .reset-button .step2 > button,
#build-page-question-layout .editor-design .editor-items .reset-button .step3 > button {
  height: 30px;
  width: 50%;
}
#build-page-question-layout .editor-design .editor-items .reset-button .step2 input.input-color {
  width: 100%;
  border: none;
  height: 40px;
  border-radius: 10px;
  background-color: #ccc;
  cursor: pointer;
}
#build-page-question-layout .editor-design .editor-items .reset-button .step2 input.input-color::-webkit-color-swatch-wrapper {
  padding: 5px;
  border-radius: 10px;
}
#build-page-question-layout .editor-design .editor-items .reset-button .step2 input.input-color::-webkit-color-swatch {
  border-radius: 10px;
}
#build-page-question-layout .editor-design .editor-items .reset-button .box {
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  margin-top: 20px;
}
#build-page-question-layout .editor-design .editor-items .reset-button .box > button {
  border: none;
  display: inline-block;
  background-color: #9a2376;
  color: #fff;
  border-radius: 5px;
  width: 150px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}
#build-page-question-layout .editor-design .editor-items .reset-button .box > button:hover {
  opacity: 0.9;
}
#build-page-question-layout .editor-css-class > strong {
  display: block;
  margin: 10px 0;
}
#build-page-question-layout .editor-css-class .items {
  width: 100%;
}
#build-page-question-layout .editor-side-texts > strong {
  display: block;
  margin: 10px 0;
}
#build-page-question-layout .editor-side-texts > .left,
#build-page-question-layout .editor-side-texts > .right {
  width: 100%;
  max-width: 320px;
  display: inline-block;
}
#build-page-question-layout .editor-side-texts > .right {
  float: right;
}

#sortable-question {
  border: solid 1px var(--color-inovyo-light);
  border-radius: 8px;
  overflow: hidden auto;
  height: 300px;
}
#sortable-question::-webkit-scrollbar {
  width: 12px;
}
#sortable-question::-webkit-scrollbar-track {
  background: #dec2d6;
  padding: 0;
  border-radius: 8px;
}
#sortable-question::-webkit-scrollbar-thumb {
  background-color: var(--color-inovyo);
  border-radius: 8px;
  width: 12px;
}
#sortable-question > .page {
  background-color: #ddd;
  font-weight: bold;
  padding: 3px 10px;
}
#sortable-question > .question {
  padding: 3px 20px;
  cursor: pointer;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
#sortable-question > .question:hover {
  background-color: #f1f1f1;
}
#sortable-question > .question.marked {
  background-color: var(--color-inovyo);
  color: white;
}
#sortable-question > .question.inactive {
  text-decoration: line-through;
  cursor: default;
  color: #aaa;
}

#build-page-sortable-box .sort-item {
  display: flex;
  border-bottom: 1px solid #ccc;
}
#build-page-sortable-box .sort-item.selected {
  background-color: #f7e7f3;
}
#build-page-sortable-box .sort-item:last-child {
  border: none;
}
#build-page-sortable-box .sort-item .btn-box button {
  margin: 5px;
}
#build-page-sortable-box .sort-item .desc-box {
  margin: 5px;
}

#upload-file-options {
  width: 100%;
}
#upload-file-options input[type=file] {
  display: none;
}
#upload-file-options .btn-upload-file {
  padding: 10px;
  background-color: var(--color-inovyo);
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  display: block;
}
#upload-file-options .btn-upload-file:hover {
  background-color: var(--color-inovyo-light);
}
#upload-file-options .btn-upload-file:active {
  background-color: var(--color-inovyo-dark);
}
#upload-file-options .preview {
  width: 100%;
  border: solid 1px #ddd;
  padding: 5px;
  height: 100px;
  overflow: hidden auto;
  border-radius: 5px;
}

@media screen and (max-width: 1200px) {
  #build-page-question-layout .editor-design .editor-items > .csat .step3 button {
    width: 32.6666666%;
  }
}
@media screen and (max-width: 600px) {
  #build-page .question-block .addition-block {
    height: 40px;
  }
  #build-page .question-block .addition-block .addition-content > .addition-content-box {
    width: 100%;
  }
  #build-page .question-block .addition-block .addition-content > .addition-content-box > .items {
    height: 20px;
    line-height: 20px;
    font-size: 12px;
  }
  #build-page .question-block:hover .addition-block {
    height: 40px;
  }

  #build-page-question-layout .editor-design .editor-items > .csat .step3 button {
    width: 49%;
  }
  #build-page-question-layout .editor-design .editor-items > .checkbox button,
#build-page-question-layout .editor-design .editor-items > .radiobox button {
    font-size: 10px;
  }
}
/** ABA ESTILOS  **/
#build-page-style .logo-tab {
  display: flex;
  gap: 30px;
  justify-content: flex-start;
  align-items: center;
}
#build-page-style .logo-tab .logo-tab-item {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
#build-page-style .logo-tab .logo-tab-item .logo-img-desktop {
  height: 100px;
  width: 100px;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 10px;
}
#build-page-style .logo-tab .logo-tab-item .logo-img-mobile {
  height: 100px;
  width: 100px;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 10px;
}
#build-page-style .colorpick-tab {
  display: flex;
  gap: 30px;
}
#build-page-style .colorpick-tab .colorpick-tab-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
#build-page-style .colorpick-tab .colorpick-tab-item .colorpick-btn {
  border-radius: 50px;
}

/** ABA COMPARTILHAR  **/
#build-page-share {
  margin-top: 90px;
  /*antd*/
}
#build-page-share .page-block {
  width: 100%;
  background-color: #fff;
  border-radius: var(--radius);
  border: solid 1px #ddd;
  padding: 0;
  margin-bottom: 60px;
  position: relative;
  overflow: hidden;
}
#build-page-share .kioski-mode-settings {
  padding: 5px;
}
#build-page-share .kioski-mode-settings > label {
  display: inline-block;
  width: 120px;
}
#build-page-share button.ant-btn {
  line-height: 25px;
}
#build-page-share button.ant-btn svg {
  font-size: 16px;
  color: #555;
}
#build-page-share .url-off {
  font-size: 14px;
}

#share-link-edition .theme-tag {
  border: solid 1px #eee;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-weight: bold;
  cursor: pointer;
}
#share-link-edition .theme-tag img {
  width: 30px;
}
#share-link-edition .theme-tag:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

div#share-theme-list {
  height: 300px;
  overflow-y: auto;
  border: solid 1px #eee;
  border-radius: 5px;
}
div#share-theme-list > .item {
  padding: 7px 10px;
  border-bottom: solid 1px #eee;
  display: flex;
  gap: 10px;
  cursor: pointer;
}
div#share-theme-list > .item:hover {
  background-color: #eee;
}
div#share-theme-list > .item img {
  width: 20px;
}
div#share-theme-list > .item.selected {
  background-color: var(--color-inovyo);
  color: #fff;
}

/** ABA GERAL  **/
#build-page-general {
  margin-top: 90px;
}

#list-language-options .ant-list-item {
  cursor: pointer;
}
#list-language-options .ant-list-item:hover {
  background-color: #e9e9e9;
}

/* TAB LÓGICA DE QUESTÃO */
#build-page-logics .btn-clear-logics {
  text-align: right;
  margin-bottom: 10px;
}
#build-page-logics .logic-card {
  background-color: #ccc;
  padding: 20px;
  gap: 5px;
  text-align: center;
}
#build-page-logics .group-card {
  overflow: hidden;
}
#build-page-logics .group-card.padding {
  padding: 5px;
  background-color: #fafafa;
  border: solid 1px #ddd;
  border-radius: 8px;
}
#build-page-logics .group-card .logic-group-card {
  padding: 5px;
  background-color: #ddd;
  border-radius: 8px;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row {
  display: flex;
  gap: 5px;
  /* layouts dinâmicos das questões selecionadas */
  /* NPS */
  /* CSAT */
  /* Radiobox */
  /* Optin */
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row.compact {
  flex-direction: row;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row.block {
  flex-direction: column;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-inputs,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin {
  background-color: white;
  border: #ddd solid 1px;
  border-radius: 5px;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps.compact,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat.compact,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox.compact,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox.compact,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-inputs.compact,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin.compact {
  width: 70%;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps.block,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat.block,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox.block,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox.block,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-inputs.block,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin.block {
  width: 100%;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps .item {
  display: inline-block;
  width: calc(25% - 2px);
  text-align: center;
  height: 30px;
  line-height: 30px;
  border: #ddd solid 1px;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps .item:hover {
  background-color: #eee;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps .item.active {
  background-color: var(--color-inovyo);
  color: white;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat .item {
  display: inline-block;
  width: calc(20% - 2px);
  text-align: center;
  height: 30px;
  line-height: 30px;
  border: #ddd solid 1px;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat .item:hover {
  background-color: #eee;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat .item.active {
  background-color: var(--color-inovyo);
  color: white;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox .item,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox .item {
  display: inline-block;
  width: calc(100% - 2px);
  text-align: center;
  height: 30px;
  line-height: 30px;
  border: #ddd solid 1px;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox .item:hover,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox .item:hover {
  background-color: #eee;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox .item.active,
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox .item.active {
  background-color: var(--color-inovyo);
  color: white;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin .item {
  display: inline-block;
  width: calc(50% - 2px);
  text-align: center;
  height: 30px;
  line-height: 30px;
  border: #ddd solid 1px;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin .item:hover {
  background-color: #eee;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin .item.active {
  background-color: var(--color-inovyo);
  color: white;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .btn-rows {
  justify-content: end;
  align-items: end;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .add-condition {
  all: unset;
  cursor: pointer;
  margin-top: 5px;
  font-size: 0.7rem;
  color: var(--color-inovyo-light);
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .add-condition :hover {
  transform: scale(1.1);
  transition: 0.2s;
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .delete-condition {
  all: unset;
  cursor: pointer;
  margin-top: 5px;
  font-size: 0.7rem;
  color: var(--color-inovyo-light);
}
#build-page-logics .group-card .logic-group-card .logic-condition-card .delete-condition :hover {
  transform: scale(1.08);
  transition: 0.2s;
}
#build-page-logics .group-card .logic-group-card .conditions-operator {
  margin: 10px 0;
}
#build-page-logics .group-card .logic-group-card .add-condition-btn {
  cursor: pointer;
  margin-top: 5px;
  font-size: 0.7rem;
  background-color: transparent;
  border: none;
}
#build-page-logics .group-card .logic-group-card-operator {
  margin: 5px;
}
#build-page-logics .group-operator {
  margin: 0;
}

/* TAB LÓGICA DE PÁGINA */
#edit-page-logic .btn-clear-logics {
  text-align: right;
  margin-bottom: 10px;
}
#edit-page-logic .logic-card {
  background-color: #ccc;
  padding: 20px;
  gap: 5px;
  text-align: center;
}
#edit-page-logic .group-card {
  overflow: hidden;
}
#edit-page-logic .group-card.padding {
  padding: 5px;
  background-color: #fafafa;
  border: solid 1px #ddd;
  border-radius: 8px;
}
#edit-page-logic .group-card .logic-group-card {
  padding: 5px;
  background-color: #ddd;
  border-radius: 8px;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row {
  display: flex;
  gap: 5px;
  /* layouts dinâmicos das questões selecionadas */
  /* NPS */
  /* CSAT */
  /* Radiobox */
  /* Optin */
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row.compact {
  flex-direction: row;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row.block {
  flex-direction: column;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-inputs,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin {
  background-color: white;
  border: #ddd solid 1px;
  border-radius: 5px;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps.compact,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat.compact,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox.compact,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox.compact,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-inputs.compact,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin.compact {
  width: 70%;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps.block,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat.block,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox.block,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox.block,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-inputs.block,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin.block {
  width: 100%;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps .item {
  display: inline-block;
  width: calc(25% - 2px);
  text-align: center;
  height: 30px;
  line-height: 30px;
  border: #ddd solid 1px;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps .item:hover {
  background-color: #eee;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-nps .item.active {
  background-color: var(--color-inovyo);
  color: white;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat .item {
  display: inline-block;
  width: calc(20% - 2px);
  text-align: center;
  height: 30px;
  line-height: 30px;
  border: #ddd solid 1px;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat .item:hover {
  background-color: #eee;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-csat .item.active {
  background-color: var(--color-inovyo);
  color: white;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox .item,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox .item {
  display: inline-block;
  width: calc(100% - 2px);
  text-align: center;
  height: 30px;
  line-height: 30px;
  border: #ddd solid 1px;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox .item:hover,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox .item:hover {
  background-color: #eee;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-radiobox .item.active,
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-checkbox .item.active {
  background-color: var(--color-inovyo);
  color: white;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin .item {
  display: inline-block;
  width: calc(50% - 2px);
  text-align: center;
  height: 30px;
  line-height: 30px;
  border: #ddd solid 1px;
  border-radius: 5px;
  margin: 1px;
  cursor: pointer;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin .item:hover {
  background-color: #eee;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .logic-condition-card-row .logic-layout-optin .item.active {
  background-color: var(--color-inovyo);
  color: white;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .btn-rows {
  justify-content: end;
  align-items: end;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .add-condition {
  all: unset;
  cursor: pointer;
  margin-top: 5px;
  font-size: 0.7rem;
  color: var(--color-inovyo-light);
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .add-condition :hover {
  transform: scale(1.1);
  transition: 0.2s;
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .delete-condition {
  all: unset;
  cursor: pointer;
  margin-top: 5px;
  font-size: 0.7rem;
  color: var(--color-inovyo-light);
}
#edit-page-logic .group-card .logic-group-card .logic-condition-card .delete-condition :hover {
  transform: scale(1.08);
  transition: 0.2s;
}
#edit-page-logic .group-card .logic-group-card .conditions-operator {
  margin: 10px 0;
}
#edit-page-logic .group-card .logic-group-card .add-condition-btn {
  cursor: pointer;
  margin-top: 5px;
  font-size: 0.7rem;
  background-color: transparent;
  border: none;
}
#edit-page-logic .group-card .logic-group-card-operator {
  margin: 5px;
}
#edit-page-logic .group-operator {
  margin: 0;
}

/* EDIT DE TEXTO */
#inovyo-wysiwyg {
  border: solid 1px #ddd;
  border-radius: 8px;
  overflow: hidden;
}
#inovyo-wysiwyg .toolbar {
  padding: 3px;
  border-bottom: solid 1px #eee;
  display: flex;
  flex-wrap: wrap;
}
#inovyo-wysiwyg .toolbar .divisor {
  height: 30px;
  width: 1px;
  background-color: #ddd;
  margin: 0 3px;
}
#inovyo-wysiwyg .toolbar button {
  outline: none;
  border: none;
  height: 30px;
  min-width: 30px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 3px;
}
#inovyo-wysiwyg .toolbar button:hover {
  background-color: #ddd;
}
#inovyo-wysiwyg .toolbar button.is-active {
  background-color: #9a2376;
  color: white;
}
#inovyo-wysiwyg .tiptap {
  padding: 10px;
  max-height: 300px;
  overflow: hidden auto;
}
#inovyo-wysiwyg .tiptap :first-child {
  margin-top: 0;
}
#inovyo-wysiwyg .tiptap > * + * {
  margin-top: 0.5em;
}
#inovyo-wysiwyg .tiptap img {
  height: auto;
  max-width: 100%;
}
#inovyo-wysiwyg .tiptap img.ProseMirror-selectednode {
  outline: 3px solid #68cef8;
}
#inovyo-wysiwyg .tiptap .max-image {
  max-width: 600px;
  outline: none;
}
#inovyo-wysiwyg .tiptap.ProseMirror-focused {
  outline: none;
  box-shadow: inset 0px 0px 10px #eee;
}
