#builder-horizontal-menu {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  float: left;
  top: 59px;
  left: 0;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px;
}
#builder-horizontal-menu::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  background-color: white;
  /* or add it to the track */
}
#builder-horizontal-menu::-webkit-scrollbar-thumb {
  width: 1px;
  height: 1px;
  background-color: white;
  /* or add it to the track */
}
#builder-horizontal-menu::-webkit-scrollbar-track {
  width: 1px;
  height: 1px;
  background-color: white;
}
#builder-horizontal-menu input.title-survey {
  font-size: large;
  font-weight: bold;
  width: calc(100% - 10px);
  margin: 5px auto;
  outline: none;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  max-width: var(--max-width);
  border-radius: 5px;
  transition: all ease-in-out 0.3s;
}
#builder-horizontal-menu input.title-survey:hover {
  border: solid 1px #b987aa;
}
#builder-horizontal-menu input.title-survey:focus {
  box-shadow: #b987aa 0px 0px 0px 2px;
  scale: 1.01;
}
#builder-horizontal-menu ul {
  height: 40px;
  background-color: white;
  width: 100%;
  border-top: solid 1px #c0a2b7;
  border-bottom: solid 1px #c0a2b7;
  max-width: var(--max-width);
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
}
#builder-horizontal-menu ul li {
  display: inline-block;
  height: 40px;
}
#builder-horizontal-menu ul li a {
  height: 40px;
  display: block;
  outline: none;
  text-decoration: none;
  line-height: 40px;
  padding: 0px 10px;
  color: #3f3f3f;
  font-size: 14px;
}
#builder-horizontal-menu ul li a:hover {
  background: linear-gradient(103deg, rgba(155, 50, 123, 0.88) 40.72%, rgba(175, 73, 73, 0.77) 132.09%);
  color: #fff;
}
#builder-horizontal-menu ul li a.active {
  background: linear-gradient(103deg, rgba(155, 50, 123, 0.88) 40.72%, rgba(175, 73, 73, 0.77) 132.09%);
  color: #fff;
}

@media all and (max-width: 1024px) {
  #builder-horizontal-menu ul {
    border-bottom: none;
    border-radius: 0px;
    overflow: inherit;
  }
}
